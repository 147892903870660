<template>
    <v-sheet elevation="2" width="100%">

        <v-toolbar short dense color="primary" dark flat>
            <v-toolbar-title>Soluciones</v-toolbar-title>

            <v-progress-linear
                v-if="loadingDatos"
                :active="loadingDatos"
                :indeterminate="loadingDatos"
                absolute
                bottom
                color="primary"
            ></v-progress-linear>

            <v-spacer></v-spacer>

            <v-btn icon @click="cargaSolucionesTodas" :disabled="muestraSol">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>


            <v-btn  rounded dark @click="dialog=true" :disabled="muestraSol" color="blue">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>

        <v-simple-table>
            <template v-slot:default>

                <thead>
                    <tr>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Id</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Detalles</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Duración</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Inicio</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Término</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Flujo</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                    </tr>
                </thead>

                <tbody>

                    <tr class="mb-3" :class="turnoColor(item.turno)+'--text'" v-for="item in datosTable" :key="item.id">

                        <td width="5%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">

                            <div v-if="item.terminado" class="text-center">

                                <v-tooltip bottom color="red" v-if="item.mar">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red lighten-2"
                                            class="mt-3 ml-1"
                                        >
                                            mdi-square
                                        </v-icon>
                                    </template>
                                    <span>Contiene medicamento de alto riesgo</span>
                                </v-tooltip>
                                <span>
                                    {{item.id}}
                                </span>
                            </div>

                            <div v-if="!item.terminado" class="text-center">
                                <v-tooltip bottom color="red" v-if="item.mar">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red lighten-2"
                                            class="mt-3 ml-1"
                                        >
                                            mdi-square
                                        </v-icon>
                                    </template>
                                    <span>Contiene medicamento de alto riesgo</span>
                                </v-tooltip>
                                <span>
                                    {{item.id}}
                                    <v-icon color="red" v-if="horaRestante(item.termino) <=0" medium>
                                        mdi-clock-alert-outline
                                    </v-icon>
                                    <v-icon color="orange" v-if="horaRestante(item.termino) > 0 && horaRestante(item.termino) <=1" medium>
                                        mdi-timer-sand
                                    </v-icon>
                                </span>
                            </div>
                        </td>

                        <td width="40%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div v-if="item.terminado">
                                <span>
                                    <b>{{item.tipoText}}<span v-if="item.tipo == 99"> {{item.tipoOtro}}</span>:</b> {{item.contenido}}<br/>
                                    <b>Por:</b> {{item.creado}}<br/>
                                </span>

                                <span v-if="item.observaciones">
                                    {{item.observaciones}}<br/>
                                </span>
                                    Terminado por: {{item.termina}}<br/>
                                    {{item.observacionesTerminado}}
                            </div>

                            <div v-if="!item.terminado">
                                <span>
                                    <b>{{item.tipoText}}<span v-if="item.tipo == 99"> {{item.tipoOtro}}</span>:</b> {{item.contenido}}<br/>
                                    <b>Por:</b> {{item.creado}}<br/>
                                    {{item.observaciones}}
                                </span>
                            </div>
                        </td>

                        <td width="5%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <span >
                                    {{item.duracion}} hrs
                                </span>
                            </div>
                        </td>

                        <td width="18%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <span> 
                                    {{item.inicio}}
                                </span>
                            </div>
                        </td>

                        <td width="18%" class="textoarriba tablaDiag" :class="claseTerminado(item)" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <span>
                                    {{item.termino}}
                                </span>
                            </div>
                        </td>

                        <td width="13%" class="textoarriba tablaDiag" @click="muestraDatos(item.diag)">
                            <div class="text-center">
                                <span>
                                    {{item.flujo}} ml/Hr
                                </span>
                                <div v-if="item.terminado && item.duracion > 0" class="font-weight-black">
                                    Total: {{$numeral(item.flujo * item.duracion).format('0,0.00')}} ml.
                                </div>
                                <div v-if="item.terminado && item.duracion == 0" class="font-weight-black">
                                    Cancelada.
                                </div>
                            </div>
                        </td>

                        <td width="10%" class="textoarriba tablaDiag" @click="muestraDatos(item.diag)">
                            <div class="text-center">

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon @click="abreCancela(item)" v-if="!item.terminado" v-on="on">mdi-eye-off</v-icon>
                                    </template>
                                    <span>Suspender</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon @click="creaCopia(item)" v-if="item.terminado" v-on="on">mdi-content-copy</v-icon>
                                    </template>
                                    <span>Copia</span>
                                </v-tooltip>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <!--
        Dialog AGREGA
        -->
        <v-dialog
            v-model="dialog"
            max-width="350"
            persistent
        >
            <v-form
            ref="solForm"
            @submit="agrega"
            onSubmit="return false;"
            v-model="validaSol"
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Agregar nueva solución
                </v-card-title>
                <v-card-text>
                    <!--
                    <v-alert type="info" text>
                        Esta solución se cargará con la fecha actual: {{this.fechaActual}}
                    </v-alert>
                    -->

                    <v-text-field
                        class="mt-3"
                        v-model="fechaActual"
                        rounded
                        outlined
                        dense
                        label="Fecha"
                        :rules="ruleFechaObligatoria"
                        hint="Formato debe ser tipo 31-01-2021"
                        persistent-hint
                    />

                    <v-select
                        class="mt-3"
                        v-model="datosSolForm.tipo"
                        :items="tipoSolucion"
                        label="Tipo"
                        rounded
                        outlined
                        dense
                        item-text="tipo"
                        item-value="id"
                        @click="cargaCatalogoSoluciones()"
                    >
                    </v-select>

                    <v-text-field
                        v-if="datosSolForm.tipo == 99"
                        v-model="datosSolForm.tipoOtro"
                        label="Seleccione Otro"
                        rounded
                        outlined
                        dense
                    >
                    </v-text-field>

                    <v-form v-model="validaHora" ref="validaHora">
                        <v-text-field
                            ref="signoHora"
                            v-model="datosSolForm.hora"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                            ]"
                            rounded
                            clearable
                            outlined
                            label="Hora de inicio (HH:MM formato 24 hrs)"
                            dense
                            hint="Despues de media noche, usar día anterior"
                        persistent-hint
                        ></v-text-field>
                    </v-form>

                    <v-text-field
                        v-model="datosSolForm.duracion"
                        :rules="[
                            value => !!value || 'Requerido.',
                            value => value >= 1 || 'Valor MENOR al mínimo (1)',
                            value => value <= 24 || 'Valor MAYOR al máximo (24)'
                        ]"
                        type="number"
                        rounded
                        outlined
                        label="Duración (hrs)"
                        dense

                    ></v-text-field>

                    <v-text-field
                        v-model="datosSolForm.flujo"
                        :rules="[
                            value => !!value || 'Requerido.',
                            value => value >= 0.01 || 'Valor MENOR al mínimo (0.01)',
                            value => value <= 10000 || 'Valor MAYOR al máximo (10,000)'
                        ]"
                        type="number"
                        rounded
                        outlined
                        label="Flujo (ml/hr)"
                        dense

                    ></v-text-field>

                    <v-text-field
                        v-model="datosSolForm.contenido"
                        :rules="[
                            value => !!value || 'Requerido.',
                        ]"
                        rounded
                        outlined
                        label="Contenido"
                        dense
                    ></v-text-field>

                    <v-text-field
                        v-model="datosSolForm.observaciones"

                        rounded
                        outlined
                        label="Observaciones"
                        dense
                    ></v-text-field>

                    <v-checkbox
                        label="Esta solución contiene MEDICAMENTOS DE ALTO RIESGO"
                        v-model="datosSolForm.mar"
                    />

                <v-expand-transition>
                    <v-alert type="info" text v-if="datosSolForm.mar">
                        Recuerde que en soluciones con medicamentos de <b>alto riesgo</b>, deberá proceder con <b>doble verificación</b>
                    </v-alert>
                </v-expand-transition>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="success"
                        type="submit"
                        :disabled="!validaSol"
                        :loading="loadingSol"
                    >
                        Agregar
                    </v-btn>

                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        </v-dialog>

        <!--
        Dialog SUSPENSE
        -->
        <v-dialog
            v-model="dialogSuspende"
            max-width="350"
            persistent
        >
            <v-form
                ref="suspendeForm"
                @submit="suspende"
                onSubmit="return false;"
                v-model="validaSusp"
            >
                <v-card>
                    <v-card-title
                        class="headline orange lighten-2"
                        primary-title
                    >
                        Suspende/termina
                    </v-card-title>
                    <v-card-text class="mt-5">

                        <v-text-field
                            v-model="datosSuspende.horas"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => value >= 0 || 'Valor MENOR al mínimo (0)',
                                value => value <= maximoSuspende || 'Valor MAYOR a duración ('+maximoSuspende+')'
                            ]"
                            type="number"
                            rounded
                            outlined
                            label="Duración (hrs)"
                            dense
                            persistent-hint
                            hint="0 para ELIMIAR esta entrada"

                        ></v-text-field>

                        <v-text-field
                            v-model="datosSuspende.observacionesTerminado"
                            label="Motivo"
                            rounded
                            outlined
                            dense
                        >
                        </v-text-field>

                        <v-expand-transition>
                            <v-checkbox
                                v-if="datosSuspende.horas>0"
                                v-model="agregaBalance"
                                label="Agregar a balance de líquidos"
                            />
                        </v-expand-transition>

                        <v-expand-transition>
                            <v-alert dense type="info" text border="left" v-if="agregaBalance && datosSuspende.horas>0">
                                Se calculará la cantidad (flujo por horas) y aplicará a balance de líquidos
                            </v-alert>
                        </v-expand-transition>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="success"
                            type="submit"
                            :disabled="!validaSusp"
                            :loading="loadingSusp"
                        >
                            Suspende
                        </v-btn>

                        <v-btn color="error" @click="cancelaSuspende">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-sheet>
</template>

<script>
export default {
    mounted(){
        this.cargaSolucionesTodas()
        this.fechaActual = this.estaFecha
    },
    props:{
        estaFecha:String,
        internamiento:Number
    },
    data:()=>({
        loadingDatos:false,
        muestraSol:false,
        datosTable:[],
        solActivo:false,

        fechaActual:'',

        // Para el modal de suspende
        dialogSuspende:false,
        validaHoraSusp:false,
        validaSusp:false,
        maximoSuspende :1,
        loadingSusp:false,
        agregaBalance:false,
        datosSuspende:{
            horas:'',
            observacionesTerminado:'',
        },
        itemActual:{},

        ruleFechaObligatoria:[
            value => !!value || "Requiere fecha de instalación",
            value => /^([0-2][0-9]|3[0-1])(-)(0[1-9]|1[0-2])\2(\d{4})$/.test(value) || "Formato debe ser tipo 31-01-2021",
        ],

        // Para el modal
        loadingSol:false,
        loadingSolList:false,
        dialog:false,
        validaSol:false,
        validaHora:false,

        tipoSolucion:[],

        datosSolForm:{
            tipo:'',
            tipoOtro:'',
            contenido:'',
            duracion:null,
            hora:'',
            flujo:'',
            observaciones:'',
        },


    }),
    methods:{

        abreCancela(itm){
            this.itemActual = {}

            this.dialogSuspende=true
            this.maximoSuspende=itm.duracion
            this.datosSuspende.horas = itm.duracion
            this.datosSuspende.observacionesTerminado = ''

            this.itemActual = itm
        },
        creaCopia(itm){
            this.cargaCatalogoSoluciones()
            this.dialog=true
            this.datosSolForm = {
                tipo:itm.tipo,
                tipoOtro:itm.tipoOtro,
                contenido:itm.contenido,
                duracion:itm.duracion,
                //hora:this.moment(new Date()).format('HH:00'),
                hora:itm.termino.split(' ')[1],
                flujo:itm.flujo,
                observaciones:itm.observaciones,
                fecha:this.fechaActual,
            }
        },
        claseTerminado(itm){
            //console.log("gradiente-"+itm.turno)
            if (itm.terminado){
                return "gradiente-"+itm.turno
            }
        },
        muestraDatos(){
            //
        },

        async cargaCatalogoSoluciones(){
            this.loadingSolList = true
            try{
                let cs = await this.$http({
                    method:'GET',
                    url:'/enfermeria/listCatalogoSoluciones',
                })
                this.tipoSolucion = cs.data
                this.loadingSolList = false
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingSolList = false
            }
        },
        async cargaSolucionesTodas(){

            this.muestraSol = true
            let params = {
                internamiento: this.internamiento,
                activo: this.solActivo,
                fecha: this.estaFecha,
            }
            try{
                //this.fechaActual=true
                let cs = await this.$http({
                    method:'GET',
                    url:'/enfermeria/listSolTodas',
                    params:params
                })
                //this.fechaActual=false
                this.datosTable = cs.data
                this.muestraSol = false
            } catch(err){
                //this.fechaActual=false
                this.$store.dispatch('security/revisaError',err)
                this.muestraSol = false
            }
        },
        async agrega(){

            //this.dialog = false
            this.loadingSol = true

            let params = this.datosSolForm

            params.internamiento = this.internamiento
            params.fecha = this.fechaActual

            params.flujo = parseFloat(this.datosSolForm.flujo)

            try {
                let ds = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaSoluciones',
                    data:params
                })

                if (ds.data.estado == true){
                    this.cargaSolucionesTodas()
                    this.dialog = false
                    this.loadingSol = false
                    this.$refs.solForm.reset()
                    this.$refs.validaHora.reset()

                } else {
                    console.log(ds.data.root)
                    this.loadingSol = false
                        this.$swal.fire({
                            icon: 'warning',
                            title: 'No se puede guardar',
                            text: 'Verifique los datos e intente de nuevo',
                        })
                }

            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                //console.log(err)
                this.loadingSol = false
            }
        },
        cancela(){
            this.agregaBalance = false
            this.dialog = false
            this.$refs.solForm.reset()
            this.$refs.validaHora.reset()
        },

        cancelaSuspende(){
            this.dialogSuspende = false
            this.agregaBalance = false
        },

        horaRestante(fin){
            let horas = 0
            fin = this.moment(fin, 'DD-MM-YYYY HH:mm').toDate().getTime()
            let actual = new Date().getTime()
            //console.log(fin)
            //console.log(actual)

            horas = (((fin - actual)/1000)/60)/60
            //console.log(horas)
            return horas
            //return horas
        },
        itemToIngreso(ite){
            let valor = 6
            switch (ite) {
                case 1:
                    valor = 18
                    break;
                case 2:
                    valor = 5
                    break;
                case 3:
                    valor = 7
                    break;
                case 4:
                    valor = 7
                    break;
                case 5:
                    valor = 4
                    break;
                case 6:
                    valor = 6
                    break;
                case 99:
                    valor = 7
                    break;
                default:
                    valor = 6
            }
            return valor
        },

        calculaTurno(hora){
            let turno = ''
            let v1 = hora.split(':')

            let hr = parseInt(v1[0], 10);
            //console.log(hr)
            if (hr >=8 && hr < 15)
                turno = 'mat'
            if (hr >=15 && hr < 21)
                turno = 'ves'
            if (hr >=21 && hr <= 23)
                turno = 'noc'
            if (hr >=0 && hr < 8)
                turno = 'noc'
            return turno
        },


        calculaFecha(inicio, final){

            // Fecha inicial a las 8:00 (del dia)
            let fi = this.moment(inicio+' 08:00', 'DD-MM-YYYY HH:mm').toDate().getTime()

            let fechaFin = final

            // La diferencia (en dias) de las dos fecha ajustado a las 24 horas
            let diferencia = (final - fi) / 86400000

            // La cantidad (en enteros) de días a agregar
            let agregar = parseInt(diferencia)
            //console.log(agregar)
            // Agrega los dias
            fechaFin = fi + (agregar * 86400000)

            // Regresa el STRING de la fecha
            return this.moment(fechaFin).format('DD-MM-YYYY')
        },
        async suspende(){
            // Obtiene la hora de inicio
            this.loadingSusp = true

            // Obriene la hora de inicio
            let horaInicio = this.moment(this.itemActual.inicio, 'DD-MM-YYYY HH:mm').toDate()
            horaInicio = horaInicio.getTime()

            let params = {
                id:this.itemActual.id,
                duracion: this.datosSuspende.horas,
                observacionesTerminado: this.datosSuspende.observacionesTerminado,
                terminado: true,
            }
            if (params.duracion < this.itemActual.duracion){
                params.observacionesTerminado = 'Original: '+this.itemActual.duracion+' Hrs. '+params.observacionesTerminado
            }

            try{
                let can = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaSoluciones',
                    data:params,
                })

                if (can.data.estado == true){
                    this.cargaSolucionesTodas()

                    // Si click en agrega y horas es mayor de 0
                    if (this.agregaBalance == true && this.datosSuspende.horas >0){

                        let dosis = 0
                        let todos = false
                        for (let i=1; i <= this.datosSuspende.horas; i++){
                            let agrega = horaInicio + (i * 3600000)
                            let fechaAgrega = this.calculaFecha(this.itemActual.fecha, agrega)

                            let prms = {
                                internamiento:this.internamiento,

                                //this.itemActual.turno
                                //fecha:this.moment(agrega).format('DD-MM-YYYY'),
                                turno: this.calculaTurno(this.moment(agrega).format('HH:mm')),

                                fecha:fechaAgrega,

                                hora:this.moment(agrega).format('HH:mm'),


                                item:this.itemToIngreso(this.itemActual.tipo),
                                itemOtro:this.itemActual.tipoOtro,

                                observaciones:'('+this.itemActual.id+') '+this.itemActual.contenido,
                                cantidad:this.itemActual.flujo,
                            }

                            try{
                                let sal = await this.$http({
                                    method:'POST',
                                    url: '/enfermeria/guardaInOut',
                                    data: prms
                                })
                                if(sal.data.estado == false){
                                    break
                                }



                            }catch(err){
                                //console.log(err)
                                break
                            }

                            dosis = dosis + this.itemActual.flujo

                            todos = true
                            //console.log(this.moment(agrega).format('DD-MM-YYYY HH:mm')+'->'+this.itemActual.flujo)
                        }

                        if (todos){
                            this.dialogSuspende = false
                            this.agregaBalance = false
                        } else {
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'No se pudo actualizar',
                                text: 'No se pudieron agregar líquidos. Verifique datos',
                            })
                        }
                        //console.log('Total aplicado: '+dosis)
                        this.loadingSusp = false
                    } else {
                        this.dialogSuspende = false
                        this.loadingSusp = false
                        this.agregaBalance = false
                    }
                } else {
                    console.log(can.data)
                    this.loadingMed = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede actualizar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.loadingSusp = false
                this.$store.dispatch('security/revisaError',err)
            }


            // Suma DURACION horas a la hora de inicio

            // desglosa fecha final

            // desglosa hora final

            // actualiza la hora

        },
        turnoColor(val){
            let text = ''

            switch (val){
                case 'mat':
                    text = 'blue'
                    break
                case 'ves':
                    text = "green"
                    break
                case 'noc':
                    text = 'red'
                    break
            }
            return text
        },

    },
    computed:{


    },
    watch:{
        solActivo(){
            this.cargaSolucionesTodas()
        },
        estaFecha(){
            this.cargaSolucionesTodas()
        },
        validaHora(){

            if (this.validaHora && this.datosSuspende.hora){

                let v1 = this.datosSuspende.hora.split(':')
                let hr = parseInt(v1[0], 10);

                if (hr < 10){
                    this.datosSuspende.hora = '0'+hr+':'+v1[1]
                } else {
                    this.datosSuspende.hora = v1[0]+':'+v1[1]
                }

            }
        },
        validaHoraSusp(){

            if (this.validaHoraSusp && this.datosSolForm.hora){

                let v1 = this.datosSolForm.hora.split(':')
                let hr = parseInt(v1[0], 10);

                if (hr < 10){
                    this.datosSolForm.hora = '0'+hr+':'+v1[1]
                } else {
                    this.datosSolForm.hora = v1[0]+':'+v1[1]
                }

            }
        }

    },
}
</script>

<style>
.textoarriba{
    vertical-align: top;

}
.tablaDiag{
    border: 1px solid  #ECECEC;
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
.gradiente-mat{
    background-image: repeating-linear-gradient( 45deg, rgba(24, 255, 255, 0.4), rgba(24, 255, 255, 0.4) 2px, #FFF 0, #FFF 10px);
}
.gradiente-ves{
    background-image: repeating-linear-gradient( 45deg, rgba(0, 255, 0, 0.3), rgba(0, 255, 0, 0.3) 2px, #FFF 0, #FFF 10px);
}
.gradiente-noc{
    background-image: repeating-linear-gradient( 45deg, rgba(255, 23, 68, 0.2), rgba(255, 23, 68, 0.2) 2px, #FFF 0, #FFF 10px);
}
</style>